import { Sheet } from "@mui/joy";
import React from "react";
import { tagsGetAll, anchorsGetAll } from '../services/apiService'
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/authService';

const Home = (props) => {
    const [tags, setTags] = React.useState([]);
    const [anchors, setAnchors] = React.useState([]);
    const [refreshMap, setRefreshMap] = React.useState(0);

    const navigate = useNavigate();

    const loadData = async (params) => {
        const cu = await isAuthenticated();

        if (cu === null) {
            navigate('/login?redirect=expiredToken');
        } else {
            try {
                await tagsGetAll().then((reply) => {
                    setTags(reply);
                    setRefreshMap(refreshMap + 1);
                });

                await anchorsGetAll().then((reply) => {
                    setAnchors(reply);
                    setRefreshMap(refreshMap + 1);
                });
            } catch (error) {
                console.log(error);
                console.log("Unknown error occured");
            }
        }
    };

    // On load
    React.useEffect(() => {
        loadData();
    }, []);

    // On data fetch (semaphore-refreshMap) sensitivity = 2
    React.useEffect(() => {
        if (refreshMap === 2) {
            setRefreshMap(0); // prevent multiple runs
        }
    }, [refreshMap])

    return (
        <Sheet>
            <h2>Home</h2>
        </Sheet>
    );
};

export default Home;